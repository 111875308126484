<template>
  <div>
    <spinner-loader :loading="loading" />
    <azkar-form
      v-if="!loading"
      :item="azkar"
      :submit="editZikr"
    />
  </div>
</template>
<script>
import AzkarForm from '@/common/components/azkar/AzkarForm.vue'
import Azkar from '@/common/compositions/azkar/AzkarApi'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'EditZikr',
  components: { AzkarForm, SpinnerLoader },
  data() {
    return {
      loading: false,
    }
  },
  setup() {
    const {
      azkar, getAzkarRequest, editAzkarRequest, prepareFormData,
    } = Azkar()
    const { successfulOperationAlert } = handleAlerts()
    return {
      azkar, getAzkarRequest, editAzkarRequest, prepareFormData, successfulOperationAlert,
    }
  },
  created() {
    this.loading = true
    this.getAzkarRequest(this.$route.params.id).then(res => {
      this.azkar = {
        ...res.data.data,

      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    editZikr() {
      this.prepareFormData()
      return this.editAzkarRequest(this.$route.params.id).then(() => {
        this.successfulOperationAlert('Zikr is updated successfully')
        this.$router.push({ name: 'azkar-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
